<template>
  <div class="order-main">
    <van-skeleton title avatar round :row="3" :loading="showSkeleton">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-if="orderList.length" :loading="loading" :finished="finished" finished-text="--全部加载完成--" :immediate-check="false" @load="onLoad">
          <img src="../../assets/images/order/banner.png" alt="" class="banner" @click="toHome">
          <div class="order-item" v-for="(item, index) in orderList" :key="index" @click="goDetails(item)">
            <div class="order-item__header">
              <img src="../../assets/logo.png" class="logo">
              <span>2025北京普惠健康保</span>
            </div>
            <div class="cornerMark" :class="setCornerMark(item)">
              <span class="statusText">{{setStatusText(item)}}</span>
            </div>
            <div class="order-item__content">
              <div class="order-item__content__item">
                <span class="label">被保险人 ：</span>
                <span class="value massage">{{item.insuredName}}</span>
              </div>
              <div class="order-item__content__item">
                <span class="label">保障期限：</span>
                <span
                  class="value">{{item.effectDateFormat&&item.effectEndDateFormat?`${item.effectDateFormat}-${item.effectEndDateFormat}`:'--'}}</span>
              </div>
              <div class="order-item__content__item">
                <span class="label">保单号：</span>
                <span class="value">{{item.policyCode || '--'}}</span>
              </div>
            </div>
          </div>
        </van-list>
        <div class="default" v-if="!orderList.length">
          <img src="../../assets/images/order/banner.png" alt="" class="banner" @click="toHome">
          <img src="../../assets/images/order/default.png" class="default__img">
          <div class="default__text">{{loading?'加载中...':'暂无数据~'}}</div>
        </div>
      </van-pull-refresh>
    </van-skeleton>
    <van-popup v-model="noticePopup" round :close-on-click-overlay="true" position="bottom" :style="{ height: popup.height }">
      <div class="popup-box noticePopup-box">
        <div v-if="popup.type===1">
          <div class="notice-tit font36 fontCol3 fontBold">退保步骤</div>
          <div class="noticePopup-content">
            <div class="notice-text font28 fontCol6 lineHei40">
              <div class="icon-index font26">1</div>联系保险公司申请退保
            </div>
            <div class="notice-text font28 fontCol6 lineHei40">
              <div class="icon-index font26">2</div>保险公司同意退保
            </div>
            <div class="notice-text font28 fontCol6 lineHei40">
              <div class="icon-index font26">3</div>银联退款<span class="low-level">（资金预计1～2个工作日原路退回）</span>
            </div>
          </div>
        </div>
        <div v-if="popup.type===2">
          <div class="notice-tit font36 fontCol3 fontBold">重新投保</div>
          <div class="noticePopup-content">
            <div class="notice-text font28 fontCol6 lineHei40 start">
              <div class="notice-text-title fontCol3">失败原因：</div>
              <div class="notice-text-details">
                北京普惠健康保参保资格校验不通过，根据医保局资格查询，参保人正确的参保类型为：{{popup.item.insuresMessage.retrySocialInsuranceName}}</div>
            </div>
            <div class="notice-text font28 fontCol6 lineHei40 start">
              <div class="notice-text-title fontCol3">投保建议：</div>
              <div class="notice-text-details">系统已将原有“{{popup.item.insuresMessage.socialInsuranceName}}”参保类型调整为：
                <span class="fontCol3">{{popup.item.insuresMessage.retrySocialInsuranceName}}</span>
              </div>
            </div>
            <div class="notice-text font28 fontCol6 lineHei40 start">
              <div class="notice-text-title fontCol3"></div>
              <div class="notice-text-details">点击下方“重新投保”按钮再次投保</div>
            </div>
          </div>
        </div>
        <div class="notice-btn font32 fontColF" v-if="popup.type===1" @click="callPhone()">联系保险公司</div>
        <div class="notice-btn font32 fontColF" v-if="popup.type===2" @click="applicationForm()">重新投保</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment';
import { day } from '@/utils'
import { supplierContactMobile } from '@/filters'
import { recordMixin } from '@/utils/mixin/recordMixin.js'
import { Dialog } from 'vant'
export default {
  name: 'Order',
  mixins: [recordMixin],
  data () {
    return {
      orderList: [],
      pageNum: 1,
      pageSize: 20,
      popup: {
        item: null,
        type: 0,
        height: '34%'
      },
      showSkeleton: false, // 骨架
      isClearList: false, // 是否清空List
      loading: false, // 滚动加载中
      finished: false, // 滚动加载完成
      isLoading: false, // 下拉强制刷新
      noticePopup: false, // 通知模态框
      isExpire: false
    }
  },
  created () {
    let { orderId } = this.$route.query
    this.orderId = orderId
    let that = this
    let expire = '2022-12-31 23:59:59'
    let n = moment().format('X');
    let e = moment(expire).format('X');
    let timer = null;
    if (+n >= +e) this.isExpire = true
    else if (n + 3600 >= +e) {
      setTimeout(() => {
        that.isExpire = true
      }, e * 1000 - n * 1000)
    }
  },
  mounted () {
    upsdk.pluginReady(function () {
      upsdk.setTitleStyle({
        navBackgroundColor: '0xffFFFFFF',
        appletStyle: 'black', //可选，black-黑色主题，white-白色主题
        backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
        appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
      })
    })
    this.queryList()
  },
  methods: {
    setCornerMark (row) {
      // if (row.orderStatus === 401 && row.payStatus === 2) { // 承保
      //   return 'danger'
      // } else if (row.orderStatus === 203 && row.payStatus === 5) { // 退款
      //   return 'info'
      // } else if (row.orderStatus === 0 || row.orderStatus === 601 || row.orderStatus === 400) { // 0失效 601退保 400承保失败
      //   return 'info'
      // } else {
      //   return 'warning'
      // }
      if (!row.policyCode) {
        return 'warning'
      } else if (row.policyCode && (row.effectDate < new Date().getTime())) {
        return 'warning'
      } else if (row.policyCode && (row.effectDate > new Date().getTime()) && (row.effectEndDate > new Date().getTime())) {
        return 'warning'
      } else if (row.policyCode && (row.effectEndDate < new Date().getTime())) {
        return 'info'
      } else {
        return 'warning'
      }
    },
    setStatusText (row) {
      if (!row.policyCode) {
        return '生成中'
      } else if (row.policyCode && (row.effectDate > new Date().getTime())) {
        return '待生效'
      } else if (row.policyCode && (row.effectDate < new Date().getTime()) && (row.effectEndDate > new Date().getTime())) {
        return '已生效'
      } else if (row.policyCode && (row.effectEndDate < new Date().getTime())) {
        return '已失效'
      } else {
        return '生成中'
      }
    },
    goDetails (row) {
      this.$router.push({ path: '/policy/details', query: { orderId: row.orderId } })
    },
    tabsChange (e) {
      console.log(e)
    },
    examineIssue (row) {
      Dialog.alert({
        message: row.message,
      }).then(() => {
        // on close
      })
    },
    onLoad () {
      if (!this.isClearList) {
        this.queryList()
      }
    },
    onRefresh () {
      this.pageNum = 1
      this.finished = false
      this.loading = false
      this.isLoading = false
      this.isClearList = true
      this.queryList()
    },
    queryList () {
      this.loading = true
      let params = {
        page: this.pageNum || 1,
        size: this.pageSize,
        queryStatus: '',
        orderId: this.orderId
      }
      this.$API.myPolicys(params).then(res => {
        console.log(res.data)
        this.loading = false
        if (!res.data || !res.data.length) {
          this.finished = true
          return
        }
        // 如果是下拉刷新就清空列表
        if (this.isClearList) {
          this.orderList = []
        }
        this.pageNum = this.pageNum + 1
        let list = (res.data && res.data) || []
        list.map(c => {
          // c.insureName = c.insures.map(c => { return c.name }).join(' / ')
          c.effectDateFormat = (c.effectDate && day.format(new Date(c.effectDate), 'YYYY/MM/DD')) || ''
          c.effectEndDateFormat = (c.effectEndDate && day.format(new Date(c.effectEndDate), 'YYYY/MM/DD')) || ''
          // c.insuresMessage = c.insures && c.insures[0]
        })
        this.orderList = this.orderList.concat(list)
        // 所有数据都加载完成后就不需要滚动加载
        if (this.orderList.length >= res.data.total) {
          this.finished = true
        }
        this.isClearList = false
      }).catch(err => {
        this.loading = false
        this.finished = true
        this.isClearList = false
        console.log('err => ', err)
      })
    },
    goPdf (row) {
      this.$router.push({ path: '/pdf', query: { url: row.elePolicyUrl } })
      // window.location.href = 'http://f4.youbaolian.cn/chexian/electronicInsurance/9803/23825/9803_10121003901809327131.PDF'
    },
    onPay (row) {
      if (this.isExpire) return this.$toast('非常抱歉，本期投保期限已过，敬请下期开放！')
      this.onRecord('Click', '立即支付')
      let that = this
      let params = {
        dtoList: [
          {
            orderId: row.orderId,
            payChannel: 'wechat',
          }
        ]
      }
      this.$API.getPayTN(params).then(res => {
        let tn = res.ReplyParams.billNo
        console.log('res => ', tn)
        upsdk.pay({
          tn: tn,
          success: function () {
            console.log('支付成功')
            that.onRecord('System', '支付结果', '支付成功')
            that.putRecord(row.orderId)
            that.$router.replace({ path: '/paid', query: { orderId: row.orderId } })
          },
          fail: function (err) {
            console.log('支付失败')
            that.onRecord('System', '支付结果', '支付失败')
            that.putRecord(row.orderId)
          }
        });
      }).catch(err => {
        console.log('err => ', err)
      })
    },
    toHome () {
      console.log('分享')
			let that = this
			upsdk.pluginReady(function () {
			  upsdk.appletSharePopup({
			  	success:function(){ 
			  		console.log('分享成功')
			  	},
			  	fail:function(){ 
			  		console.log('分享失败')
			  	}
			  })
			});
    },
    toService (row) {
      this.popup.type = 1
      this.popup.height = '34%'
      this.noticePopup = true
      this.popup.item = row
    },
    toAnew (row) {
      if (this.isExpire) return this.$toast('非常抱歉，本期投保期限已过，敬请下期开放！')
      this.popup.type = 2
      this.popup.height = '42%'
      this.noticePopup = true
      this.popup.item = row
    },
    callPhone () {  // 联系客服
      this.onRecord('Click', '联系客服')
      window.location.href = `tel:${supplierContactMobile(this.popup.item.companyCode)}`
      upsdk.pluginReady(function () {
        upsdk.setTitleStyle({
          navBackgroundColor: '0xffffffff',
          appletStyle: 'black', //可选，black-黑色主题，white-白色主题
          backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
          appletTitleBarVisible: '1', // 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
        })
      })
    },
    applicationForm () {
      this.onRecord('Click', '重新投保')
      let params = {
        dto: {
          orderCode: this.popup.item.orderCode,
          retrySocialInsuranceType: this.popup.item.insuresMessage.retrySocialInsuranceType
        }
      }
      this.$API.addNewOrder(params).then(res => {
        this.onRecord('Click', '重新投保', `将原有“${this.popup.item.insuresMessage.socialInsuranceName}”参保类型调整为${this.popup.item.insuresMessage.retrySocialInsuranceName}`)
        let data = res.ReplyParams
        this.onPay(data)
      }).catch(err => {
        console.log('err => ', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order-main {
  // min-height: 100vh;
  background-color: #f7f7f7;
  // overflow-y: auto;
  // padding-top: 32px;
  box-sizing: border-box;
  text-align: left;
  /deep/ .van-pull-refresh {
    padding-top: 20px;
    height: 100vh !important;
    overflow: auto !important;
    background-color: #f7f7f7;
  }
  .banner {
    width: 710px;
    height: 289px;
    margin: 0 auto;
    display: block;
    margin-bottom: 33px;
  }
  .order-item {
    width: 686px;
    height: 346px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-bottom: 25px;
    position: relative;
    background-image: url("../../assets/images/policy/gbCard.png");
    &__header {
      display: flex;
      align-items: center;
      position: absolute;
      left: 32px;
      top: 28px;
      .logo {
        width: 56px;
        height: 56px;
        margin-right: 32px;
      }
      span {
        font-size: 36px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
      }
    }
    .cornerMark {
      position: absolute;
      right: 0;
      top: 0;
      width: 204px;
      height: 76px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-size: 28px;
      color: #fff;
      overflow: hidden;
      &.info {
        background-image: url("../../assets/images/order/cornerMark_info.png");
      }
      &.danger {
        background-image: url("../../assets/images/order/cornerMark_danger.png");
      }
      &.warning {
        background-image: url("../../assets/images/order/cornerMark_warning.png");
      }
      .statusText {
        // position: absolute;
        // right: 24px;
        // top: 6px;
        display: inline-block;
        margin-left: 90px;
        margin-top: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
    }
    &__content {
      width: 622px;
      height: 200px;
      background-color: #fff;
      position: absolute;
      border-radius: 16px;
      backdrop-filter: blur(10px);
      top: 105px;
      left: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      padding: 28px;
      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        .label {
          display: block;
          width: 150px;
          height: 40px;
          font-size: 28px;
          color: #7d8ba2;
        }
        .value {
          color: #40577e;
          font-size: 28px;
        }
        .refund {
          display: flex;
          align-items: center;
          .icon {
            margin-left: 44px;
            color: #ff3333;
            font-size: 26px;
          }
          .streamer {
            position: relative;
            background-color: #fff0e4;
            height: 40px;
            line-height: 40px;
            font-size: 24px;
            padding: 0 11px;
            font-weight: 300;
            margin-left: 10px;
            border-radius: 4px;
            color: #f9bf8c;
            &::before {
              content: "";
              position: absolute;
              left: -7px;
              top: 50%;
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border-top: 8px solid transparent;
              border-right: 8px solid #fff0e4;
              border-bottom: 8px solid transparent;
            }
          }
        }
        .issue-icon {
          margin-left: 22px;
          color: #ff3333;
          font-size: 28px;
        }
        &.button-content {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          .btn {
            width: 128px;
            height: 52px;
            border-radius: 8px;
            font-size: 24px;
            text-align: center;
            line-height: 52px;
            margin-left: 40px;
          }
          .solidBtn {
            background-color: #fd3147;
            color: #fff;
            &.disabled {
              background-color: #cccccc;
              color: #ffffff;
              pointer-events: none;
            }
          }
          .hollowBtn {
            border: 2px solid #fd3147;
            color: #fd3147;
          }
        }
      }
    }
  }
  .default {
    font-family: PingFangSC-Regular, PingFang SC;
    &__img {
      width: 364px;
      height: 306px;
      display: block;
      margin: 0 auto;
      margin-top: 200px;
    }
    &__text {
      margin-top: 48px;
      font-size: 24px;
      color: #999;
      text-align: center;
    }
  }
  .popup-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .noticePopup-box {
    padding: 32px 36px 36px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .notice-tit {
      text-align: center;
    }
    .noticePopup-content {
      .icon-index {
        display: inline-block;
        width: 40px;
        height: 40px;
        color: #5a4444;
        background-color: #ffded9;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        margin-right: 16px;
      }
      .notice-text {
        margin-top: 32px;
        display: flex;
        align-items: center;
        .low-level {
          color: #cccccc;
        }
        .notice-text-title {
          width: 146px;
          line-height: 40px;
        }
        .notice-text-details {
          flex: 1;
          line-height: 40px;
        }
      }
      .start {
        align-items: flex-start;
      }
    }
    .notice-btn {
      width: 100%;
      height: 98px;
      background-color: #ff0904;
      border-radius: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
